<template>
  <div>
    <!-- <c-search-box @enter="getList">
      <template slot="search">
        <div class="col-xs-12 col-sm-12 col-md-2 col-lg-2 col-xl-2">
          사용여부
          <c-select
            :editable="editable"
            type="search"
            :comboItems="useFlagItems"
            itemText="codeName"
            itemValue="code"
            label="LBLUSEFLAG"
            name="useFlag"
            v-model="searchParam.useFlag">
          </c-select>
        </div>
      </template>
    </c-search-box> -->
    <div class="row">
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="fstTable"
          title="대분류 목록"
          tableId="fstTable"
          rowKey="iimOccurMasterId"
          :editable="editable"
          :isExcelDown="false"
          :columns="grid1.columns"
          :data="grid1.data"
          @rowClick="rowClick"
        >
          <!-- 버튼 영역 -->
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && firstEditable" label="LBLADD" icon="add" :showLoading="false" @btnClicked="addrow1" />
              <c-btn
                v-if="editable && firstEditable && grid1.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave1"
                :param="grid1.data"
                mappingType="POST"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveClass1"
                @btnCallback="saveCallback1" />
              <!-- <c-btn label="LBLSEARCH" icon="search" @btnClicked="getList" /> -->
              <!-- <c-btn v-if="firstEditable && grid1.data.length > 0" :showLoading="false" label="LBLREMOVE" icon="remove" @btnClicked="remove1"/> -->
            </q-btn-group>
          </template>
          <template v-slot:customArea="{ props, col }">
            <template v-if="col.name === 'click'">
              <q-chip
                v-if="props.row.editFlag !== 'C'"
                outline square
                color="blue"
                :clickable="true"
                :editable="editable"
                text-color="white"
                @click="rowClick(props.row, col.name)">
                ▶
              </q-chip>
            </template>
          </template>
        </c-table>
      </div>
      <div class="col-xs-12 col-sm-12 col-md-6 col-lg-6 col-xl-6">
        <c-table
          ref="secTable"
          title="중분류 목록"
          tableId="secTable"
          :editable="editable"
          rowKey="iimOccurMasterId"
          :isExcelDown="false"
          :columns="grid2.columns"
          :data="grid2.data"
        >
          <!-- 버튼 영역 -->
          <template slot="table-chip">
            <q-btn-group outline >
              <!-- <q-chip>
                  <q-avatar icon="push_pin" color="green" text-color="white" /> 
                  {{ "대분류 : " + popupData.rowOccurName}}
              </q-chip> -->
            </q-btn-group>
          </template>
          <template slot="table-button">
            <q-btn-group outline >
              <c-btn v-if="editable && secondEditable" label="LBLADD" icon="add" :showLoading="false" @btnClicked="addrow2" />
              <c-btn
                v-if="editable && secondEditable && grid2.data.length > 0"
                :url="saveUrl"
                :isSubmit="isSave2"
                :param="grid2.data"
                mappingType="POST"
                label="LBLSAVE"
                icon="save"
                @beforeAction="saveClass2"
                @btnCallback="saveCallback2" />
              <!-- <c-btn v-if="secondEditable && grid2.data.length > 0" :showLoading="false" label="LBLREMOVE" icon="remove" @btnClicked="remove2"/> -->
            </q-btn-group>
          </template>
        </c-table>
      </div>
    </div>
    <c-dialog :param="popupOptions"></c-dialog>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import selectConfig from '@/js/selectConfig';
import transactionConfig from '@/js/transactionConfig';
export default {
  name: 'occur-form',
  data() {
    return {
      searchParam: {
        useFlag: 'Y',
        occurLevel: '1',
      },
      grid1: {
        columns: [
          {
            reqired: true,
            name: 'occurName',
            field: 'occurName',
            label: '대분류명',
            type: 'text',
            align: 'left',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
          {
              name: 'click',
              field: 'click',
              label: '중분류',
              align: 'center',
              style: 'width:50px',
              sortable: false,
              type: "custom",
            },
        ],
        data: [],
      },
      grid2: {
        columns: [
          {
            name: 'occurName',
            field: 'occurName',
            label: '중분류명',
            type: 'text',
            align: 'left',
            sortable: true,
          },
          {
            name: 'useFlag',
            field: 'useFlag',
            label: '사용여부',
            align: 'center',
            style: 'width:70px',
            type: 'check',
            true: 'Y',
            false: 'N',
            sortable: false,
          },
        ],
        data: [],
      },
      popupData: {
        first: '',
        rowOccurName: '',
      },
      useFlagItems: [ // 사용여부
        { code: 'Y', codeName: '사용' },
        { code: 'N', codeName: '미사용' },
      ],
      type: '',
      editable: true,
      listUrl: '',
      popupOptions: {
        target: null,
        title: '',
        visible: false,
        width: '60%',
        param: {},
        closeCallback: null,
      },
      isSave1: false,
      isSave2: false,
      saveUrl: '',
      updateUrl: '',
      deleteUrl: '',

    };
  },
  computed: {
    firstEditable() {
      return this.editable;
    },
    secondEditable() {
      return this.editable && this.popupData.first;
    },
  },
  beforeCreate() {},
  created() {},
  beforeMount() {
    Object.assign(this.$data, this.$options.data());
  },
  mounted() {
    this.init();
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      // role setting
      this.editable = this.$route.meta.editable;
      // url setting
      this.listUrl = selectConfig.sop.iim.occurForm.list.url;
      this.saveUrl = transactionConfig.sop.iim.occurForm.save.url;
      this.deleteUrl = transactionConfig.sop.iim.occurForm.delete.url;
      // code setting
      // list setting
      // 점검유형 데이터 받은 후 조회 처리
      this.getList();
    },
    getList() {
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = this.searchParam;
      this.$http.request((_result) => {
        this.grid1.data = _result.data;
        this.grid2.data = []
        this.popupData.first = null;
        this.popupData.second = null
      },);
    },
    rowClick(row) {
      if (row.editFlag && row.editFlag === 'C') return;
      this.$http.url = this.listUrl;
      this.$http.type = 'GET';
      this.$http.param = {
        iimUpOccurMasterId: row.iimOccurMasterId,
        occurLevel: '2',
      }
      this.$http.request((_result) => {
        this.grid2.data = _result.data;
        this.popupData.first = row.iimOccurMasterId;
        this.popupData.rowOccurName = row.occurName;
      },);
    },
    /* eslint-disable no-unused-vars */
    addrow1() {
      this.grid1.data.splice(0, 0, {
        iimOccurMasterId: uuidv4(),
        iimUpOccurMasterId: '',
        occurName: '',
        occurLevel: '1',
        useFlag: 'Y',
        editFlag: 'C',
      })
    },
    remove1() {
      let selectData = this.$refs['fstTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        window.getApp.$emit('CONFIRM', {
        title: 'LBLCONFIRM',
        message: 'MSGREMOVE', // 삭제하시겠습니까?,
        // TODO : 필요시 추가하세요.
        type: 'warning', // success / info / warning / error
        // 확인 callback 함수
        confirmCallback: () => {
          this.$http.url = this.deleteUrl;
          this.$http.type = 'DELETE';
          this.$http.param = {
            data: Object.values(selectData)
          }
          this.$http.request(() => {
            this.$_.forEach(selectData, item => {
              this.grid1.data = this.$_.reject(this.grid1.data, { iimOccurMasterId: item.iimOccurMasterId })
            })
            window.getApp.$emit('APP_REQUEST_SUCCESS');
          },);
        },
        // 취소 callback 함수
        cancelCallback: () => {
        },
      });
      }
    },
    saveClass1() {
      let flag = true;
      this.$_.forEach(this.grid1.data, item => {
        if (!item.occurName) {
            window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '대분류명을 입력하세요.',
            type: 'warning', // success / info / warning / error
          });
          flag = false;
        }
      })
      if (flag) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave1 = !this.isSave1;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    addrow2() {
      this.grid2.data.splice(0, 0, {
        iimOccurMasterId: uuidv4(),
        iimUpOccurMasterId: this.popupData.first,
        occurName: '',
        occurLevel: '2',
        useFlag: 'Y',
        editFlag: 'C',
      })
    },
    remove2() {
      let selectData = this.$refs['secTable'].selected;
      if (!selectData || selectData.length === 0) {
        window.getApp.$emit('ALERT', {
          title: 'LBLGUIDE', // 안내
          message: 'MSGNOITEMS', // 선택된 항목이 없습니다.
          type: 'warning', // success / info / warning / error
        });
      } else {
        
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGREMOVE', // 삭제하시겠습니까?,
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.$http.url = this.deleteUrl;
            this.$http.type = 'DELETE';
            this.$http.param = {
            data: Object.values(selectData)
          }
            this.$http.request((_result) => {
              this.$_.forEach(selectData, item => {
                this.grid2.data = this.$_.reject(this.grid2.data, { iimOccurMasterId: item.iimOccurMasterId })
              })
              this.deleteCallback(_result)
            },
            () => {
            });
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveClass2() {
      let flag = true;
      this.$_.forEach(this.grid2.data, item => {
        if (!item.occurName) {
            window.getApp.$emit('ALERT', {
            title: 'LBLGUIDE', // 안내
            message: '중분류명을 입력하세요.',
            type: 'warning', // success / info / warning / error
          });
          flag = false;
        }
      })
      if (flag) {
        window.getApp.$emit('CONFIRM', {
          title: 'LBLCONFIRM',
          message: 'MSGSAVE', // 저장하시겠습니까?
          // TODO : 필요시 추가하세요.
          type: 'info', // success / info / warning / error
          // 확인 callback 함수
          confirmCallback: () => {
            this.isSave2 = !this.isSave2;
          },
          // 취소 callback 함수
          cancelCallback: () => {
          },
        });
      }
    },
    saveCallback1() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.getList();
    },
    saveCallback2() {
      window.getApp.$emit('APP_REQUEST_SUCCESS');
      this.rowClick({ iimOccurMasterId: this.popupData.first, occurName: this.popupData.rowOccurName })
    },
  }
};
</script>
